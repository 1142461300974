<template>
  <main>
    <a class="twitter-timeline" data-lang="fr" href="https://twitter.com/CTS_Infostrafic?ref_src=twsrc%5Etfw">Tweets by CTS_Infostrafic</a>
  </main>
</template>

<script>

export default {
  head: {
    title: 'Directs',
    titleTemplate: '%s - Infos trafic',
    htmlAttrs: {
      lang: 'fr'
    }
  },
  mounted() {
    let script = document.createElement('script');
    script.setAttribute('src','https://platform.twitter.com/widgets.js');
    document.head.appendChild(script);
  }
};
</script>
