<template>
  <!-- Liste des arrêts -->
  <div class="stoplist">
    <ul>
      <li
        v-for="stop in stopsWithFavs"
        :key="stop.StopPointRef"
        class="stop">
        <router-link
          :to="'/stop/'+stop.Extension.LogicalStopCode"
        >{{stop.StopName}}
        </router-link>
        <button v-show="favoris !== null && !favoris[stop.Extension.LogicalStopCode]" class="fav no" v-on:click.stop="favorize(stop.Extension.LogicalStopCode)"></button>
        <button v-show="favoris !== null && favoris[stop.Extension.LogicalStopCode]" class="fav ok" v-on:click.stop="unfavorize(stop.Extension.LogicalStopCode)"></button>
      </li>
    </ul>
    <p v-if="!filter && favoris && !all" class="seeall">
      <button v-on:click.prevent="seeall">Voir tous les arrêts</button>
    </p>
  </div>
</template>

<script>

import Vue from 'vue';

export default {
  name: 'StopList',
  props: {
    stops: {
      type: Array,
      default: function() { return [] }
    },
    filter: { // Un filtre est-il appliqué sur les arrêts par le parent ? (recherche texte)
      type: Boolean,
      default: false
    }
  },
  computed: {
    // Arrêts avec favoris
    stopsWithFavs() {
      if(!this.filter && this.favoris && Object.keys(this.favoris).length > 0) {
        if(this.all) { // Voir tous (suite au clic sur le bouton)
          return this.stops.slice().sort((a,b) => {
            if(this.favoris[a.Extension.LogicalStopCode] && !this.favoris[b.Extension.LogicalStopCode]) return -1
            if(this.favoris[b.Extension.LogicalStopCode] && !this.favoris[a.Extension.LogicalStopCode]) return 1
            return 0
          })
        } else { // Voir juste les favoris (par défaut)
          return this.stops.filter((s) => {
            if(this.favoris[s.Extension.LogicalStopCode]) return true
          })
        }
      } else {
        return this.stops
      }
    }
  },
  data() {
    return {
      favoris: null,
      all: false
    }
  },
  mounted() {
    try {
      let ls = window.localStorage.getItem('favs')
      if(ls) {
        this.favoris = JSON.parse(ls);
      }
      // Première visite/initialisation
      if(!this.favoris) {
        window.localStorage.setItem('favs',JSON.stringify({}));
        this.favoris = {}
      }
    } catch {
      console.error('JSON favs parsing error');
      window.localStorage.setItem('favs',JSON.stringify({}));
      this.favoris = {}
    }
  },
  methods: {
    favorize(StopCode) {
      Vue.set(this.favoris, StopCode, true) // Réactivité !
      window.localStorage.setItem('favs',JSON.stringify(this.favoris));
    },
    unfavorize(StopCode) {
      Vue.delete(this.favoris, StopCode)
      window.localStorage.setItem('favs',JSON.stringify(this.favoris));
    },
    seeall() {
      this.all = true
      this.$emit('all')
    }
  }
};
</script>

<style lang="scss">
.fav {
  border: 0;
  color: orange;
  background: #fefefe;
  border-radius: 50%;
  border: 2px solid transparent;
  display: inline-block;
  margin: 0;
  line-height: 2rem;
  width: 3rem;
  height: 3rem;
  padding: 0;
  margin-left: 0.5rem;
  outline: 0;
  &:focus, &:active, &:hover {
    border: 2px solid darkorange;
  }
  &.ok {
    background: orange;
    color: white;
  }
  &.no:before {
    content: '☆';
    font-size: 1rem;
  }
  &.ok:before {
    background: orange;
    color: white;
    content: '★';
    font-size: 1rem;
  }
}
.seeall {
  text-align: center;
}
</style>
