import Vue from 'vue';
import Router from 'vue-router';
import index from './views/index.vue';
import lines from './views/lines.vue';
import trafic from './views/trafic.vue';
import stop from './views/stop.vue';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'index',
      component: index,
    },
    {
      path: '/lines',
      name: 'lines',
      component: lines,
    },
    {
      path: '/trafic',
      name: 'trafic',
      component: trafic,
    },
    {
      path: '/stop/:id',
      name: 'stop',
      component: stop,
    },
    {
      path: '/timetable/:id',
      name: 'timetable',
      component: () => import(/* webpackChunkName: "timetable" */ './views/timetable.vue'),
    },
    {
      path: '/mentions',
      name: 'mentions',
      component: () => import(/* webpackChunkName: "mentions" */ './views/mentions.vue'),
    },
  ],
});
