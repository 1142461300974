<template>
  <main>
    <Loader v-if="!$store.state.stops">...Chargement des arrêts en cours...</Loader>
    <div class="stops" v-if="filteredStops">

      <div class="inputs">
        <p class="search">
          <label for="q">Rechercher un arrêt...</label>
          <input type="text" id="q" class="q" placeholder="🔍 Nom de l'arrêt..." v-on:focus="geoClear" v-model="filterStops">
        </p>
        <p class="geo" v-if="!$store.state.geocoords">
          <button v-on:click="geoloc" v-if="!geoloading">🌍 Arrêts proches</button>
          <template v-else><Loader></Loader></template>
        </p>
      </div>

      <p v-text="errorMsg" v-if="errorMsg" class="error msg"></p>
      <p v-if="filteredStops.length < 1" class="error msg">Aucun arrêt trouvé...</p>

      <StopList
        :stops="filteredStops"
        :filter="filterStops.length > 0 || geofilter"
        v-on:all="filterStops = ''">
      </StopList>

    </div>

  </main>
</template>

<script>
import Loader from '@/components/Loader.vue';
import StopList from '@/components/StopList.vue';
import config from '@/config';

export default {
  name: 'index',
  components: {
    Loader,
    StopList,
  },
  data() {
    return {
      ip: '', // Adresse IP
      filterStops: '', // Filtre des arrêts par caractères
      ref: this.makeid(8),
      errorMsg: false,
      geofilter: false, // En train de filtrer par géoloc
      geoloading: false, // passe à true lorsqu'on est correctement géolocalisé
      dataRefreshInterval: null, // intervalle de rafraîchissement des données API
    };
  },
  computed: {
    // Liste d'arrêts réduite pour éviter les doublons de noms, et triée par ordre alpha
    reducedStops() {
      return this.$store.state.stops.StopPointsDelivery.AnnotatedStopPointRef.reduce((unique, item) => {
        if (!unique.find(e => { return e.StopName == item.StopName })) { // Pas déjà dans le tableau
          return [... unique, item]
        } else { // Déjà dans le tableau
          return unique
        }
      }, []).sort((a,b) => { // Tri alpha
        if (a.StopName > b.StopName) return 1
        else if (a.StopName < b.StopName) return -1
        else return 0
      })
    },
    // Arrêts filtrés (ou non) par la recherche
    filteredStops() {
      if (!this.filterStops && this.$store.state.stops) { // Sans filtre
        return this.reducedStops;
      } else if (this.filterStops && this.$store.state.stops) { // Avec filtre
        return this.reducedStops.filter(s => {
          // return (s.StopName.indexOf(this.filterStops) > -1)
          return (s.StopName.toLowerCase().indexOf(this.filterStops.toLowerCase()) > -1)
        });
      }
      return []
    }
  },
  mounted() {
    // Chargement des lignes, depuis local ou API (voir store)
    this.$store.dispatch('fetchLines', false);
    // Chargement des arrêts
    this.$store.dispatch('fetchStopPoints', false);
    // Mise à jour des données à intervalles réguliers depuis l'API si nécessaire
    if(config.refreshIntervalFromApi) {
      var doRefresh = false
      if(!this.$store.state.dataRefreshLastDate) {
        doRefresh = true
      } else {
        let ld = this.$store.state.dataRefreshLastDate
        if(ld) {
          // Si la date est trop ancienne, malgré l'intervalle autorisé
          if(ld + config.refreshIntervalFromApi < Date.now()) {
            doRefresh = true
          }
        } else {
          doRefresh = true
        }
      }
      if(doRefresh) {
        setTimeout(() => {
          this.$store.dispatch('fetchLines', true);
        }, 5000)
        setTimeout(() => {
          this.$store.dispatch('fetchStopPoints', true);
        }, 6000)
      }
    }
  },
  methods: {

    // Génère un identifiant unique aléatoire
    makeid(length) {
      let text = ''
      const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      for (let i = 0; i < length; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length))
      return text;
    },

    // Géolocalisation
    geoloc() {
      this.geoloading = true
      this.filterStops = ''
      this.errorMsg = false
      navigator.geolocation.getCurrentPosition(this.geoSuccess, this.geoError)
    },
    geoSuccess(position) {
      this.geoloading = false
      this.errorMsg = false
      if (position && position.coords) {
        this.$store.commit('setGeocoords', {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          accuracy: position.coords.accuracy
        })
        if (position.coords.accuracy > 1000) {
          this.errorMsg = 'Géolocalisation imprécise (>1 km)';
        }
        this.geofilter = true
        this.$store.dispatch('fetchStopPoints', true) // force update
      }
    },
    geoError(err) {
      this.geoloading = false
      this.geofilter = false
      const msgs = {
        1: 'Vous n\'avez pas donné la permission',
        2: 'Position indisponible',
        3: 'Temps écoulé',
      };
      this.errorMsg = 'Erreur de géolocalisation (' + msgs[err.code] + ')'
    },
    // Annulation de la géoloc
    geoClear() {
      this.$store.commit('clearGeocoords')
      this.geofilter = false
      this.$store.dispatch('fetchStopPoints', true) // force update
    }
  },
};
</script>

<style lang="scss">

@import "@/assets/global.scss";

main {
  .search, .msg {
    display: flex;
    padding: 0 1rem;
    margin-bottom: 0.5rem;
  }
  .refreshdate {
    padding: 1rem 1rem 0 0;
    margin: 0;
    color: #666;
    font-size: 0.75rem;
    text-align: right;
  }
  .inputs {
    display: flex;
    justify-content: space-between;
  }
  .search {
    flex-grow: 1;
    label {
      display: none;
    }
    .q {
      font-size: inherit;
      padding: $input-padding;
      min-height: 1.5rem;
      flex-basis: 100%;
      border: $input-border;
      border-radius: $border-radius;
      -webkit-appearance: none;
      outline: none;
    }
    .q:focus {
      border-color: $focus-color;
    }
  }
  .geo {
    text-align: center;
    padding: 0 1rem 0 0;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    button {
      line-height: 2rem;
      flex-grow: 1;
      font-size: inherit;
      border: 2px solid #fff;
      color: #444;
      padding: $button-padding;
      border-radius: $border-radius;
      cursor: pointer;
      background: transparent;
    }
  }
}
</style>
