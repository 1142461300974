import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

// Produit une estimation de temps restant
Vue.filter('estimate', function(value) {
  if (!value) return ''
  value = value.toString()
  let now = new Date()
  let d = new Date(value)
  var dif = d.getTime() - now.getTime()
  if(dif > 0) {
    dif = dif / 1000
    if(dif < 60)
      return Math.round(dif) + ' s'
    else if(dif < 3600)
      return Math.round(dif/60) + ' min'
    else if(dif < 86400)
      return Math.round(dif/3600) + ' h'
  }
})

// Produit une estimation de temps restant "(dans ...)"
Vue.filter('estimatein', function(value) {
  if (!value) return ''
  value = value.toString()
  let now = new Date()
  let d = new Date(value)
  var dif = d.getTime() - now.getTime()
  var v = null
  if(dif > 0) {
    dif = dif / 1000
    if(dif < 60)
      v = Math.round(dif) + ' s'
    else if(dif < 3600)
      v = Math.round(dif/60) + ' min'
    else if(dif < 86400)
      v = Math.round(dif/3600) + ' h'
  }
  if(v) {
    return '(dans '+v+')'
  }
})

// Génère un emoji/icône bus/tram
Vue.filter('emojize', function (value) {
  if (!value) return ''
  value = value.toString()
  switch (value) {
    case 'bus':
      return '🚌'
    case 'tram':
      return '🚈'
    default:
      break;
  }
})

// Transforme en date humaine
Vue.filter('timize', function(value) {
  if (!value) return ''
  value = value.toString()
  let now = new Date()
  let d = new Date(value)
  let txt = ''
  if(d) {
    if(d.getDate() !== now.getDate()) { // Jour différent
      txt += 'Demain '
    }
    txt += [d.getHours(), d.getMinutes(), d.getSeconds()]
    .map((num) => { return `0${num}`.slice(-2) })
    .join(':')
  }
  return txt
})

Vue.filter('durationize', function(value) {
  if (!value) return ''
  value = value.toString()
  value = value.replace('PT','')
  return value
})
