// Variables de configuration

const config = {
  app: {
    title: 'cts.blup.fr',
  },
  axios: {
    baseURL: 'https://api.cts-strasbourg.eu',
    auth: {
      username: '15111f89-81c8-44c0-b627-f1fcbdfa9de6',
      password: '',
    },
  },
  refreshIntervalFromApi: 2 * 86400 * 1000 // intervalle de refresh des données en ms (= 2 jours)
};

export default config;
