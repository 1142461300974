import Vue from 'vue';
import Vuex from 'vuex';
import config from '@/config';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lines: null,
    stops: null,
    timetable: null,
    geocoords: null,
    dataRefreshLastDate: parseInt(localStorage.getItem('refreshLastDate')) // Dernière date de rafraîchissement des données (lines/stops) depuis l'API
  },
  getters: {
    simpleLines: (state) => {
      if(state.lines && state.lines.LinesDelivery && state.lines.LinesDelivery.AnnotatedLineRef)
        return state.lines.LinesDelivery.AnnotatedLineRef
      else return null
    },
    stopFromId: (state) => (id) => {
      if(state.stops && state.stops.StopPointsDelivery && state.stops.StopPointsDelivery.AnnotatedStopPointRef) {
        return state.stops.StopPointsDelivery.AnnotatedStopPointRef.filter(s => {
          return (s.StopPointRef == id)
        })[0]
      }
    },
    stopFromLogicalStopCode: (state) => (id) => {
      if(state.stops && state.stops.StopPointsDelivery && state.stops.StopPointsDelivery.AnnotatedStopPointRef) {
        return state.stops.StopPointsDelivery.AnnotatedStopPointRef.filter(s => {
          return (s.Extension.LogicalStopCode == id)
        })[0]
      }
    }
  },
  mutations: {
    setlines (state, lines) {
      state.lines = lines
    },
    setStops (state, stops) {
      state.stops = stops
    },
    setTimetable (state, timetable) {
      state.timetable = timetable
    },
    clearTimetable (state) {
      state.timetable = null
    },
    setGeocoords (state, coords) {
      state.geocoords = coords
    },
    clearGeocoords (state) {
      state.geocoords = null
    },
    setDataRefreshLastDate (state, timestamp) {
      state.dataRefreshLastDate = timestamp
      // On mémorise en local aussi
      localStorage.setItem('refreshLastDate', timestamp)
    }
  },
  actions: {
    // Récupère les lignes et les stocke
    async fetchLines({commit, state}, force) { // force = oblige API réseau
      if (!state.lines || force == true) {
        var lines = null
        try {
          // Si on est en mode force, on ne pioche pas dans localStorage
          if(!force) lines = localStorage.getItem('lines')
          if(lines) {
            // console.info('Lines : loaded from localStorage')
            commit('setlines', JSON.parse(lines))
          }
        } catch(e) {
          // console.info('Lines : cannot load from localStorage')
          lines = null
        }
        if(!lines) {
          lines = await axios.get(
            '/v1/siri/2.0/lines-discovery',
            config.axios
          );
          if(lines) {
            // console.info('Lines : loaded from network')
            localStorage.setItem('lines', JSON.stringify(lines.data))
            commit('setlines', lines.data)
          }
        }
      }
    },

    // Récupère une timetable
    async fetchTimetable({commit}, lineref) {
      let params = {
        LineRef: lineref
      };
      let timetable = await axios.get(
        '/v1/siri/2.0/estimated-timetable',
        { ...config.axios, params }
      );
      commit('setTimetable', timetable.data)
    },

    // Récupère les arrêts et les stocke
    async fetchStopPoints({commit, state}, force) { // force = oblige API réseau
      if (!state.stops || force == true) {
        var stops = null
        var params = {}
        if(state.geocoords) { // Avec géoloc : nécessite l'API distante
          params = {
            latitude: state.geocoords.latitude,
            longitude: state.geocoords.longitude,
            distance: 400 // distance de découverte en mètres
          };
        } else { // Sinon on peut utiliser la liste mémorisée en local
          try {
            // Si on est en mode force, on ne pioche pas dans localStorage
            if(!force) stops = localStorage.getItem('stops')
            if(stops) {
              // console.info('Stops : loaded from localStorage')
              commit('setStops', JSON.parse(stops))
            }
          } catch(e) {
            // console.info('Stops : cannot load from localStorage')
            stops = null
          }
        }

        if(!stops) {
          stops = await axios.get(
            '/v1/siri/2.0/stoppoints-discovery',
            { ...config.axios, params }
          );
          if(stops) {
            // console.info('Stops : loaded from network')
            // On les mémorise en local s'ils ne proviennent pas d'une requête géoloc filtrante
            if(!state.geocoords) {
              localStorage.setItem('stops', JSON.stringify(stops.data))
            }
            commit('setStops', stops.data)
            commit('setDataRefreshLastDate', Date.now())
          }
        }

      }
    },
  },
});
