<template>
  <main>
    <div class="lines">
      <ul v-if="sortedLines">
        <li
          v-for="(line, index) in sortedLines"
          :key="index" class="line">
          <router-link :to="'/timetable/'+line.LineRef">
            <template v-if="line.LineRef.length<=2">
              <span class="lineref" :style="'background-color:#'+line.Extension.RouteColor+';color:#'+line.Extension.RouteTextColor">
                {{line.LineRef}}
              </span>
            </template>
            <template v-else>
              <b class="linereflong">
                {{line.LineRef}}
              </b>
            </template>
            <span class="routetype">{{ line.Extension.RouteType.toUpperCase() }}</span>
            <span class="linename">{{ line.LineName }}</span>
          </router-link>
        </li>
      </ul>
      <Loader v-else>... Chargement en cours ...</Loader>
    </div>
  </main>
</template>

<script>
import Loader from "@/components/Loader.vue";

export default {
  name: 'Lines',
  head: {
    title: 'Directs',
    titleTemplate: '%s - Lignes',
    htmlAttrs: {
      lang: 'fr',
    },
  },
  components: {
    Loader,
  },
  computed: {
    sortedLines() {
      if(this.$store.getters.simpleLines) {
        return this.$store.getters.simpleLines.slice().sort((a,b) => {
          if(a.LineRef.length > b.LineRef.length || a.Extension.RouteType == 'bus' && b.Extension.RouteType !== 'bus') return 1;
          else if(b.LineRef.length > a.LineRef.length || b.Extension.RouteType == 'bus' && a.Extension.RouteType !== 'bus') return -1;
          else return 0;
        })
      } else {
        return []
      }
    }
  },
  mounted() {
    this.$store.dispatch('fetchLines', true); // On ne sait jamais, si on va sur cette page...
  },
};
</script>

<style lang="scss">

@import "@/assets/global.scss";

.routetype {
  margin: 0 0.5rem;
  padding: 0 1rem 0 0.5rem;
  border-right: 1px solid #ccc;
}

</style>
