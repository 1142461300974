<template>
  <main>

    <div class="monitorings" v-if="monitorings">
      <h2><router-link class="back" to="/"></router-link> {{ stopName }}</h2>

      <!-- Liste des horaires -->
      <ul v-if="filteredMonitorings && filteredMonitorings.length > 0">
        <li
          v-for="(monitoring, index) in filteredMonitorings"
          :key="index"
          class="monitoring">
          <span class="vehicle-line-destination">
            <span class="vehicle-line">
              <span class="vehicle">{{monitoring.MonitoredVehicleJourney.VehicleMode | emojize }}</span>
              <span class="lineref" :style="lineStyle(monitoring.MonitoredVehicleJourney.PublishedLineName)">
                {{ monitoring.MonitoredVehicleJourney.PublishedLineName }}
              </span>
            </span>
            <span class="destination">
              {{monitoring.MonitoredVehicleJourney.DestinationName}}
            </span>
          </span>
          <span class="arrival">
            <!-- <span class="delay" v-if="monitoring.MonitoredVehicleJourney.Delay !== 'PT0S'">⏳ Retard {{ monitoring.MonitoredVehicleJourney.Delay | durationize }}</span> -->
            <!-- v-else -->
            <span class="estimate">{{monitoring.MonitoredVehicleJourney.MonitoredCall.ExpectedArrivalTime | estimatein }}</span>
            {{monitoring.MonitoredVehicleJourney.MonitoredCall.ExpectedArrivalTime | timize }}
          </span>
        </li>
      </ul>
      <p v-else class="error">Aucun passage trouvé pour le moment</p>

      <p class="refreshdate" v-if="lastRefreshDate">Données chargées à {{ lastRefreshDate | timize }}</p>

    </div>

    <Loader v-else></Loader>

  </main>
</template>

<script>
import config from '@/config';
import axios from 'axios';

import Loader from '@/components/Loader.vue';

export default {
  name: 'Stop',
  head: {
    title: 'Directs',
    titleTemplate: '%s - Arrêt',
    htmlAttrs: {
      lang: 'fr'
    },
  },
  components: {
    Loader
  },
  mounted() {
    this.$store.dispatch('fetchLines', false);
    if (this.$route.params && this.$route.params.id) {
      this.setStopMonitoring(this.$route.params.id);
    }
    this.$store.dispatch('fetchStopPoints', false);
    // Initialisation du rafraîchissement auto
    this.refreshInterval = setInterval(() => { this.refresh() }, 1000 * 30) // toutes les 30s
  },
  data() {
    return {
      currentStopId: null, // ID Arrêt sélectionné
      monitorings: null, // Passages à l'arrêt
      refreshInterval: null,
      lastRefreshDate: null,
    };
  },
  computed: {
    filteredMonitorings() {
      return this.monitorings.ServiceDelivery.StopMonitoringDelivery[0].MonitoredStopVisit
    },
    currentStop() {
      let stop = null
      if (this.currentStopId && this.currentStopId.match(/^[0-9]+$/)) {// valeur numérique seulement avec LogicalStopCode
        stop = this.$store.getters.stopFromLogicalStopCode(this.currentStopId)
      } else {
        stop = this.$store.getters.stopFromId(this.currentStopId)
      }
      if (stop) return stop
      else return null
    },
    stopName() {
      if (this.currentStop) return this.currentStop.StopName
      else return ''
    }
  },
  watch: {
    stopName(newStopName) {
      if(newStopName) document.title = config.app.title + ' - ' + newStopName
    }
  },
  methods: {
    lineStyle(lineName) {
      if (this.$store.state.lines) {
        let line = this.$store.state.lines.LinesDelivery.AnnotatedLineRef.filter((l) => { return l.LineRef == lineName })[0]
        if (line) {
          let extension = line.Extension
          if (extension)
          return {
            color:'#'+extension.RouteTextColor,
            backgroundColor:'#'+extension.RouteColor
          }
        }
      }
    },
    refresh() {
      if (this.currentStopId !== null) {
        this.getStopMonitoring()
      }
    },
    // Définit l'arrêt courant à monitorer
    setStopMonitoring(stop) {
      this.currentStopId = stop
      this.getStopMonitoring()
    },
    // Récupère les passages à un arrêt
    async getStopMonitoring() {
      // On ne conserve que la première partie du nom de l'arrêt
      if (this.currentStopId) {
        // let realStop = this.currentStopId.split('_')[0];
        let params = {
          MonitoringRef: this.currentStopId,
          RequestorRef: ''
        };
        let response = await axios.get(
          "/v1/siri/2.0/stop-monitoring",
          { ...config.axios, params }
        )
        this.monitorings = response.data
        this.lastRefreshDate = new Date().toISOString()
      }
    },
  }
};
</script>

<style lang="scss">

@import "@/assets/global.scss";

main {
  li.monitoring {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $separator-color;
  }
  .arrival {
    font-size: 1.5rem;
    color: #444;
  }
  .delay {
    color: darkorange;
    margin-left: auto;
  }
  .estimate {
    margin-left: auto;
    color: #999;
    font-size: 1rem;
  }
}

@media (min-width: 480px) {
  main {
    li.monitoring {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
}

</style>
